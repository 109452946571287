import { TableScrollDirective } from './directives/table-scroll.directive';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { AuthInterceptor } from './interceptor/auth-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AppTranslationModule } from '../app.translation.module';
import { NaturalNumberDirective } from './directives/natural-number.directive';
import { AccessRightsDirective } from './directives/access-rights.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterPipe } from './directives/pipes/filter.pipe';
@NgModule({
  declarations: [
    ConfirmDialogComponent,
    DataTableComponent,
    ModalDialogComponent,
    BottomSheetComponent,
    NaturalNumberDirective,
    AccessRightsDirective,
    TableScrollDirective,
    FilterPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AppTranslationModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AppTranslationModule,
    MaterialModule,
    DataTableComponent,
    BottomSheetComponent,
    NaturalNumberDirective,
    AccessRightsDirective,
    TableScrollDirective,
    NgxMatSelectSearchModule,
    FilterPipe,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ModalDialogComponent,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,
  }],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
