import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [AuthGuard],
    redirectTo: 'pages',
    pathMatch: 'full',
  },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
