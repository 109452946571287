import { AuthService } from './auth/services/auth.service';
import { MyProfileComponent } from './profile/component/my-profile/my-profile.component';
import { ChangePasswordComponent } from './profile/component/change-password/change-password.component';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MENUS } from './page.menu';
import { StorageService } from 'src/app/shared/services/storage.service';
import { MatDialog } from '@angular/material';
import { permissionConstant } from 'src/environments/permission';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  adminMenu: string[] = permissionConstant.ADMIN_MENU;
  configMenu: string[] = permissionConstant.CONFIG_MENU;
  
  languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español (Spanish)' },
    { code: 'ch', name: '中文 (Chinese)' },
    { code: 'pt', name: 'Português (Portuguese)' },
  ];
  mobileQuery: MediaQueryList;
  fillerNav = MENUS;
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private storageService: StorageService, private _dialog: MatDialog, private _authService: AuthService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {}

  logout() {
    this._authService.logout();
  }

  customToogle(event,nav) {
    event.elementRef.nativeElement.classList.toggle('sidenavCollapsed');
    nav._elementRef.nativeElement.classList.toggle('collapsed')
  }

  changeLanguage(lang: string) {
    this.storageService.store('lang', lang);
    location.reload();
  }
  
  changePassword() {
    this._dialog.open(ChangePasswordComponent, { panelClass: 'change-pwd-dialog' });
  }
  
  viewProfile() {
    this._dialog.open(MyProfileComponent, { panelClass: 'my-profile-dialog' });
  }
}
