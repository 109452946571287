import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { ProfileService } from '../../service/profile.service';
import { takeUntil } from 'rxjs/operators';
import { CustomValidators } from 'src/app/shared/classes/custom-validators';
import { ApiService } from 'src/app/shared/services/api.service';
import { EncryptionService } from 'src/app/encryption.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnDestroy {
  resetPasswordForm: FormGroup;
  private unsubscribe$ = new Subject<void>();
  constructor(
      public snackBar: MatSnackBar,
      private _fb: FormBuilder,
      private _profileService: ProfileService,
      private _apiService: ApiService,
      private _encryptionService: EncryptionService,
      private _dialogRef: MatDialogRef<ChangePasswordComponent>) {
    this.resetPasswordForm = this.createResetPasswordForm();
  }

  createResetPasswordForm(): FormGroup {
    return this._fb.group(
      {
        oldPassword: [null, Validators.compose([Validators.required])],
        password: [null,
          Validators.compose(
            [Validators.required,
              Validators.pattern(/^[^\s].+[^\s]$/),	// white-space at the beginning and  at the end	not allow
              CustomValidators.patternValidator(/\d/, { hasNumber: true }), // number
              CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }), // upper case
              CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }), // lower case
              CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }), // special character
              Validators.minLength(8),
            ]),
        ],
        password_confirmation: [null, Validators.compose([Validators.required])],
      },
      {
        validator: CustomValidators.passwordMatchValidator, // password and confirm password match
      });
  }
  
  encryptParams() {
    this._apiService.isLoggedInState.next('show');
    const credentials = {
      oldPassword: this.resetPasswordForm.controls['oldPassword'].value.trim(),
      password: this.resetPasswordForm.controls['password'].value.trim(),
      password_confirmation: this.resetPasswordForm.controls['password_confirmation'].value.trim(),
    };
    this._encryptionService.encryptRequestParams(credentials).pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (res) => {
      await this.resetPassword({ input : res });
    });
  }
    
  resetPassword(resetPasswordParams) {
    this._profileService.changeUserPassword(resetPasswordParams).
          pipe(takeUntil(this.unsubscribe$)).
          subscribe(res => {
            this.showToast(res.message);
            this._dialogRef.close();
            this._apiService.isLoggedInState.next('hide');
          }, error => {
            this.showToast(error.error.message, 'snackbar-error');
          });
  }

  showToast(msg, panelClass = 'snackbar-success') {
    this.snackBar.open(msg, 'Close', { duration: 5000, panelClass: [panelClass] });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

