import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileService } from './service/profile.service';
import { MyProfileComponent } from './component/my-profile/my-profile.component';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number'

@NgModule({
  declarations: [ChangePasswordComponent, MyProfileComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    SharedModule.forRoot(),
    InternationalPhoneNumberModule,
  ],
  entryComponents: [
    ChangePasswordComponent,
    MyProfileComponent,
  ],
  providers: [ProfileService],
})
export class ProfileModule { }
