import { StorageService } from '../../../shared/services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api.service';
import { Injectable } from '@angular/core';
import { apiConstant } from 'src/environments/api.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  exportCSVparams: any = {};
  constructor(private _apiService: ApiService , private _http: HttpClient, private storageService: StorageService) { }

  addSerialNumberFile(params: any): Observable<any[]> {
    const fileData = new FormData();
    fileData.append('csvFile', params, params.name);
    return this._apiService.fileUpload(apiConstant.API_ADD_SERIAL_NUMBER, fileData).pipe(
      map((response: Response) => <any><unknown>response));
  }
  updateSerialNumberFile(params: any) {
    const fileData = new FormData();
    fileData.append('csvFile', params, params.name);
    return this._apiService.fileUpload(apiConstant.API_UPDATE_SERIAL_NUMBER, fileData).pipe(
      map((response: Response) => <any><unknown>response));
  }
  deleteSerialNumberFile(params: any) {
    const fileData = new FormData();
    fileData.append('csvFile', params, params.name);
    return this._apiService.fileUpload(apiConstant.API_DELETE_SERIAL_NUMBER, fileData).pipe(
      map((response: Response) => <any><unknown>response));
  }
  getConfigFiles(filter?, sort_by?, sort_dir?, page?, take?): Observable<any> {
    const params: any = {};
    filter ? params.q = filter : null;
    sort_by ? params.sort_by = sort_by : null;
    sort_dir ? params.sort_dir = sort_dir : null;
    Number.isInteger(page) ? params.page = page : null;
    take ? params.per_page = take : null;
    return this._apiService.get(apiConstant.API_CONFIG_FILE_LIST, { params }).pipe(map((response: Response) => <any><unknown>response));
  }

  downloadConfigFile(params: any): Observable<any> {
    const headers = new HttpHeaders().set('authorization', 'Bearer' + this.storageService.getStorageItem('accessToken'));
    return this._http.post(apiConstant.API_DOWNLOAD_CONFIG_FILE, params, { headers, responseType: 'blob' as 'json' })
                .pipe(map((response: Response) => <any><unknown>response));
  }
  
  
  getProductionHistoryData(filter?, sort_by?, sort_dir?, page?, take?): Observable<any> {
    const params: any = {};
    filter ? params.q = filter : null;
    sort_by ? params.sort_by = sort_by : null;
    sort_dir ? params.sort_dir = sort_dir : null;
    Number.isInteger(page) ? params.page = page : null;
    take ? params.per_page = take : null;
    return this._apiService.get(apiConstant.API_PRODUCTION_FILE_HISTORY, { params }).pipe(map((response: Response) => <any><unknown>response));
  }

  getSerialNumbersList(filter?, sort_by?, sort_dir?, page?, take?, advFilter?): Observable<any> {
    const params: any = {};
    
    
    sort_by ? params.sort_by = sort_by : null;
    sort_dir ? params.sort_dir = sort_dir : null;
    Number.isInteger(page) ? params.page = page : null;
    take ? params.per_page = take : null;
    advFilter && advFilter['customer_id'] ? params.customer_id = advFilter['customer_id'] : null;
		advFilter && advFilter['search_type'] ? params.search_type = advFilter['search_type'] : null;
		advFilter && advFilter['search_by'] ? params.q = advFilter['search_by'].trim() : null;
		advFilter && advFilter['so_number'] ? params.so_number = advFilter['so_number'].trim() : null;
		this.exportCSVparams = params;
    return this._apiService.get(apiConstant.API_GET_SERIAL_NUMBERS, { params }).pipe(map((response: Response) => <any><unknown>response));
  }
  
  getSearchType(): Observable<any> {
    return this._apiService.get(apiConstant.API_GET_SEARCH_TYPE, { }).pipe(map((response: Response) => <any><unknown>response));
  }
  
  getCustomer(): Observable<any> {
    return this._apiService.get(apiConstant.API_GET_CUSTOMER_ID, { }).pipe(map((response: Response) => <any><unknown>response));
  }

  downloadProductionFile(id) {
    return this._apiService.get(apiConstant.API_GET_PRODUCTION_FILE_BY_ID+ '/' + id, { }).pipe(map((response: Response) => <any><unknown>response));
  }
  
  exportNodeAsCsv(){
    let params = this.exportCSVparams;
    delete params.page;
    delete params.per_page;
    params.exportAs = true;
    
    return this._http.get(apiConstant.API_GET_SERIAL_NUMBERS,{ params }).pipe(
        map((response: Response) => <any><unknown>response));
}
  
}

