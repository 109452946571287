import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { merge, interval, Subject } from 'rxjs';
import { tap, delay, finalize, takeUntil } from 'rxjs/operators';
import { MatTableDataSource, MatDialog, MatSnackBar, MatPaginator, MatSort, MatBottomSheet, MatPaginatorIntl } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { PaginatorConfig } from './paginator.config';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { ConfigurationService } from 'src/app/page/configuration/services/configuration.service';
import { ApiService } from '../../services/api.service';
import exportFromJSON from 'export-from-json';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  dataSourceTable: MatTableDataSource<any>;
  dataUnavailable: boolean = false;
  take: number = 20;
  page: number = 0;
  interval: any;
  loader: boolean = true;

  sortActive;
  sortDirection;
  searchValue: string;
  refreshOff: boolean = true;
  advFilter: any;
  private unsubscribe: Subject<void> = new Subject();

  @Input() tableLoading: boolean = true;
  @Input() polling: boolean = false;
  @Input() displayedColumns: string[];
  @Input() dataSource: any;
  @Input() sortBy: string[];
  @Input() sortDir: any;
  @Input() search: any;
  @Input() isActive: any;
  @Input() inUse: any;
  @Input() paginationType: string;
  


  @Output() download = new EventEmitter<any[]>();
  @Output() edit = new EventEmitter<any[]>();
  @Output() delete = new EventEmitter<any[]>();
  @Output() checkbox = new EventEmitter<any[]>();
  @Output() switch = new EventEmitter<any>();
  @Output() newData = new EventEmitter<any>();
  @Output() sortByItem = new EventEmitter<any>();
  @Output() sortDirItem = new EventEmitter<any>();
  @Output() searchBarItem = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _configurationService: ConfigurationService,
    private _apiService: ApiService, 
    ) { }

  ngOnInit() {
    this.dataSource.loadingSubject.next(true);
    this.paginator.pageSize = this.pageSizesByType();
    this.dataSource.load(this.search, this.sortBy, this.sortDir, this.page, this.paginator.pageSize, this.advFilter);
    this.initializeTable();
    this.searchValue = (this.route.snapshot.queryParams, 'search');
    this.searchValue ? this.doFilter(this.searchValue) : null;
  }

  ngOnChanges() {
    this.searchValue = this.search ? this.search : this.searchValue;
    if (this.searchValue !== null) {
      this.page = 0;
      this.paginator.pageIndex = 0;
      setTimeout(() => {
        this.loadData();
      }, 300);
    }
  }

  initializeTable() {

    this.dataSource.data$.pipe(finalize(() => { this.dataSource.loadingSubject.next(false); }))
    .subscribe(res => {
      this.dataSource.loadingSubject.next(true);
      this.dataUnavailable = res.length ? false : true;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.searchValue ? this.doFilter(this.searchValue) : null;
      if (res.length >= 0 || res.length) {
        this.tableLoading = false;
      } else {
        this.tableLoading = true;
      }
    });
  }

  loadData() {
    this.paginator.pageSize = this.pageSizesByType();  
    this.dataSource.load(this.search, this.sort.active, this.sort.direction, (this.paginator) ? this.paginator.pageIndex : 1,
        this.paginator.pageSize, this.advFilter );
    this.initializeTable();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => {
          this.loadData();
        }))
        .subscribe(res => {
        });
  }

  ngOnDestroy() {
    this.polling = false;
    clearInterval(this.interval);
  }

  getNewData() {
    this.paginator.pageSize = this.pageSizesByType();
    this.searchBarItem.emit(this.searchValue);

    const data = {
      dataSource: this.dataSource,
      search: this.search,
      sortBy: this.sort.active,
      sortDir: this.sort.direction,
      page: (this.paginator) ? this.paginator.pageIndex + 1 : 1,
      take: this.paginator.pageSize,
    };
    this.newData.emit(data);
  }

  pauseInterval() {
    this.polling = false;
    setTimeout(() => {
      this.polling = true;
    }, 5000);
  }

  onPaginateChange(event) {
    this.page = event.pageIndex;
    this.onPageSizeChange(event);
  }

  refresh(data?) {
    if (data) {
      this.search = data.searchValue;
      this.sortBy = data.sortBy;
      this.sortDir = data.sortDir;
    }

    this.refreshOff = false;
    this.dataSource.load(this.searchValue, this.sortBy, this.sortDir, (this.paginator) ? this.paginator.pageIndex + 1 : 1, this.paginator.pageSize, this.advFilter);
  }

  doFilter(value: string) {
    this.searchValue = value;
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  doCustFilter(filter) {
    this.advFilter = filter;
  }
  
  downloadFile(element) {
    this.download.emit(element);
  }

  downloadProductionFiles(element) {
    this._apiService.isLoggedInState.next('show');
    this._configurationService.downloadProductionFile(element.id)
    .pipe(takeUntil(this.unsubscribe), finalize(() => {}))
    .subscribe(async (response) => {
      this._apiService.isLoggedInState.next('hide');
      if (response.code == 200) {
        if(response.data.fileExtension === 'json'){
          await this.loadJSON(response.data.url, element.filename, response.data.fileExtension);
        } else {
          window.open(response.data.url, '_blank');
        }
      }
    });
  }

  loadJSON(path, fileName, exportType) {
    fetch(path)
    .then(response  =>  response.json())
    .then(function (response) {
      let data = JSON.stringify(response); 
      exportFromJSON({ data, fileName, exportType });
    })
    .catch(function (error) {
      console.error('error', error);
    });
  }

  deleteUser(element) {
    this.delete.emit(element);
  }
  
  editUser(element) {
    this.edit.emit(element);
  }
  
  /* To copy any Text */
copyText(val: string){
  if (val !== null && val !== '') {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.showToast('Copied to clipboard', 'snackbar-success');
  } else {
    this.showToast('Falid to copy', 'snackbar-error');
  }
}

  showToast(msg, panelClass) {
    this._snackBar.open(msg, 'Close', { duration: 5000, panelClass: [panelClass] });
  }
  
  bottomSheetComponentValues(items) {
    this.bottomSheet.open(BottomSheetComponent, {
      data: items,
    });
  }


  editItem(item: any) {
    this.edit.emit(item);
  }

  deleteItem(item: any) {
    this.delete.emit(item);
  }

  checkboxChange(item: any) {
    this.checkbox.emit(item);
  }

  pageSizesByType() {
    const paginatorConfig = JSON.parse(localStorage.getItem('paginator')) as PaginatorConfig;
    return paginatorConfig[this.paginationType] === undefined ? paginatorConfig['self'] : paginatorConfig[`${this.paginationType}`];
  }

  onPageSizeChange(event) {
    this.dataSource.loadingSubject.next(true);
    const paginatorConfig = new PaginatorConfig(JSON.parse(localStorage.getItem('paginator')));
    paginatorConfig.setValue(this.paginationType, event.pageSize);
    localStorage.setItem('paginator', JSON.stringify(paginatorConfig));
    this.loadData();
  }
}
