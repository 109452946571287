import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor(public title: string, public message: string) {}
}
  
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnDestroy {
  title: string;
  message: string;
  private unsubscribe: Subject<void> = new Subject();
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    this.getTitle(data); // Update view with given values
  }

  getTitle(data) {
    this.translate.get(data.title).pipe(takeUntil(this.unsubscribe), finalize(() => {}))
    .subscribe( async titleRes => {
      this.title = titleRes;
      await this.getMessage(data);
    });
  }
  getMessage(data) {
    this.translate.get(data.message).pipe(takeUntil(this.unsubscribe), finalize(() => {}))
    .subscribe(messageRes => {
      this.message = messageRes;
    });
  }

  onConfirm(): void {
    this.dialogRef.close(true); // Close the dialog, return true
  }

  onDismiss(): void {
    this.dialogRef.close(false); // Close the dialog, return false
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

